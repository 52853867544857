export default {
  namespaced: true,
  state: {
    totalVzAuth: [
      {
        tabName: "Build Information",
        componentName: "VbgBuildInfoCard",
        data: [
          {
            env: "QA1",
            data: [
              {
                name: "AM Service",
                // url: "https://mbaccmgrqa1.sdc.vzwcorp.com/vzauth/keepAlive.jsp",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa1buildinfo/vzauth/buildinfo",
                // url: "https://mbaccmgrqa1.sdc.vzwcorp.com/vzauth/buildinfo",
                flag: false,
              },
              {
                name: "IG Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa1buildinfo/account/buildinfo",
                // url: "https://mbaccmgrqa1.sdc.vzwcorp.com/ig/status.html",
                flag: false,
              },
              {
                name: "IAM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa1buildinfo/ifgservices/vbg/iamservices/buildinfo",
                // url: "https://mbaccmgrqa1.sdc.vzwcorp.com/ifgservices/vbg/aaservices/healthcheck",
                flag: false,
              },
              //   {
              //     name: "VSBDR QA1",
              //     url: "http://10.119.34.87:8090/ciam/registrationservice/actuator/health",
              //     flag: false,
              //   },
              {
                name: "Adaptive Auth",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa1buildinfo/ifgservices/vbg/aaservices/buildinfo",
                flag: false,
              },
              {
                name: "UI Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa1buildinfo/account/business/login/buildinfo",
                flag: false,
              },
              {
                name: "B2B Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/b2bbuildinfo/qa1/am-services/build.jsp",
                flag: false,
              },
            ],
            reloadCount: 0,
            isRefresh: false,
          },
          {
            env: "QA2",
            data: [
              {
                name: "AM Service",
                // url: "https://mbaccmgrqa2.sdc.vzwcorp.com/vzauth/keepAlive.jsp",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa2buildinfo/vzauth/buildinfo",
                flag: false,
              },
              {
                name: "IG Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa2buildinfo/account/buildinfo",
                flag: false,
              },
              {
                name: "IAM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa2buildinfo/ifgservices/vbg/iamservices/buildinfo",
                flag: false,
              },
            
              {
                name: "Adaptive Auth",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa2buildinfo/ifgservices/vbg/aaservices/buildinfo",
                flag: false,
              },
              {
                name: "UI Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa2buildinfo/account/business/login/buildinfo",
                flag: false,
              },
              {
                name: "B2B Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/b2bbuildinfo/q2/am-services/build.jsp",
                flag: false,
              },
            ],
            reloadCount: 0,
            isRefresh: false,
          },
          {
            env: "QA3",
            data: [
              {
                name: "AM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa3buildinfo/vzauth/buildinfo",
                flag: false,
              },
              {
                name: "IG Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa3buildinfo/account/buildinfo",
                flag: false,
              },
              {
                name: "IAM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa3buildinfo/ifgservices/vbg/iamservices/buildinfo",
                flag: false,
              },
            
              {
                name: "Adaptive Auth",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa3buildinfo/ifgservices/vbg/aaservices/buildinfo",
                flag: false,
              },
              {
                name: "UI Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa3buildinfo/account/business/login/buildinfo",
                flag: false,
              },
              {
                name: "B2B Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/b2bbuildinfo/qa3/am-services/build.jsp",
                flag: false,
              },
            ],
            reloadCount: 0,
            isRefresh: false,
          },
          {
            env: "QA4",
            data: [
              {
                name: "AM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa4buildinfo/vzauth/buildinfo",
                flag: false,
              },
              {
                name: "IG Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa4buildinfo/account/buildinfo",
                flag: false,
              },
              {
                name: "IAM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa4buildinfo/ifgservices/vbg/iamservices/buildinfo",
                flag: false,
              },
            
              {
                name: "Adaptive Auth",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa4buildinfo/ifgservices/vbg/aaservices/buildinfo",
                flag: false,
              },
              {
                name: "UI Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa4buildinfo/account/business/login/buildinfo",
                flag: false,
              },
              {
                name: "B2B Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/b2bbuildinfo/qa4/am-services/build.jsp",
                flag: false,
              },
            ],
            reloadCount: 0,
            isRefresh: false,
          },
          {
            env: "QA5",
            data: [
              {
                name: "AM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa5buildinfo/vzauth/buildinfo",
                flag: false,
              },
              {
                name: "IG Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa5buildinfo/account/buildinfo",
                flag: false,
              },
              {
                name: "IAM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa5buildinfo/ifgservices/vbg/iamservices/buildinfo",
                flag: false,
              },
              {
                name: "Adaptive Auth",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa5buildinfo/ifgservices/vbg/aaservices/buildinfo",
                flag: false,
              },
              {
                name: "UI Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/qa5buildinfo/account/business/login/buildinfo",
                flag: false,
              },
              {
                name: "B2B Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/b2bbuildinfo/qa5/am-services/build.jsp",
                flag: false,
              },
            ],
            reloadCount: 0,
            isRefresh: false,
          },
          {
            env: "Canary",
            data: [
              {
                name: "AM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/canarybuildinfo/vzauth/buildinfo",
                flag: false,
              },
              {
                name: "IG Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/canarybuildinfo/account/buildinfo",
                flag: false,
              },
              {
                name: "IAM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/canarybuildinfo/ifgservices/vbg/iamservices/buildinfo",
                flag: false,
              },
              {
                name: "Adaptive Auth",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/canarybuildinfo/ifgservices/vbg/aaservices/buildinfo",
                flag: false,
              },
              {
                name: "UI Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/canarybuildinfo/account/business/login/buildinfo",
                flag: false,
              },
              // {
              //   name: "B2B Service",
              //   url: "https://mbaccmgrqa4.sdc.vzwcorp.com/b2bbuildinfo/canary/am-services/build.jsp",
              //   flag: false,
              // },
            ],
            reloadCount: 0,
            isRefresh: false,
          },
          {
            env: "UAT",
            data: [
              {
                name: "AM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/uatbuildinfo/vzauth/buildinfo",
                flag: false,
              },
              {
                name: "IG Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/uatbuildinfo/account/buildinfo",
                flag: false,
              },
              {
                name: "IAM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/uatbuildinfo/ifgservices/vbg/iamservices/buildinfo",
                flag: false,
              },
              {
                name: "Adaptive Auth",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/uatbuildinfo/ifgservices/vbg/aaservices/buildinfo",
                flag: false,
              },
              {
                name: "UI Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/uatbuildinfo/account/business/login/buildinfo",
                flag: false,
              },
              {
                name: "B2B Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/b2bbuildinfo/uat/am-services/build.jsp",
                flag: false,
              },
            ],
            reloadCount: 0,
            isRefresh: false,
          },
          {
            env: "Production",
            data: [
              {
                name: "AM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodbuildinfo/vzauth/buildinfo",
                flag: false,
              },
              {
                name: "IG Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodbuildinfo/account/buildinfo",
                flag: false,
              },
              {
                name: "IAM Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodbuildinfo/ifgservices/vbg/iamservices/buildinfo",
                flag: false,
              },
              {
                name: "Adaptive Auth",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodbuildinfo/ifgservices/vbg/aaservices/buildinfo",
                flag: false,
              },
              {
                name: "UI Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodbuildinfo/account/business/login/buildinfo",
                flag: false,
              },
              {
                name: "B2B Service",
                url: "https://mbaccmgrqa4.sdc.vzwcorp.com/b2bbuildinfo/prod/am-services/build.jsp",
                flag: false,
              },
            ],
            reloadCount: 0,
            isRefresh: false,
          },
        ],
      },
      {
        tabName: "Quick Links",
        componentName: "VbgQuickLinks",
        data: [],
      },
      {
        tabName: "Prodcution Blue/Green Serve Status",
        componentName: "VbgHealthCheck",
        data: [
          {
            env: "AM services",
            subEnvironment: [
              {
                env: "tdc",
                cards: [
                  // {
                  //   flag: false,
                  //   url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t1op1",
                  // },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t1op2",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t1op3",
                  },
                  // {
                  //   flag: false,
                  //   url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t2op1",
                  // },
                  // {
                  //   flag: false,
                  //   url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t2op2",
                  // },
                  // {
                  //   flag: false,
                  //   url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t2op3",
                  // },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t3op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t3op2",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t3op3",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t4op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t4op2",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/t4op3",
                  },
                ],
                reloadCount: 0,
                isRefresh: false,
              },
              {
                env: "sdc",
                cards: [
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s1op1",
                  },
                  // {
                  //   flag: false,
                  //   url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s1op2",
                  // },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s1op3",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s2op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s2op2",
                  },
                  // {
                  //   flag: false,
                  //   url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s2op3",
                  // },
                  // {
                  //   flag: false,
                  //   url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s3op1",
                  // },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s3op2",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s3op3",

                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s4op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s4op2",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodambuildinfo/s4op3",
                  },
                ],
                reloadCount: 0,
                isRefresh: false,
              },
            ],
          },
          {
            env: "IAM services",
            subEnvironment: [
              {
                env: "tdc",
                cards: [
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodiambuildinfo/t1op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodiambuildinfo/t2op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodiambuildinfo/t3op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodiambuildinfo/t4op1",
                  },
                ],
                reloadCount: 0,
                isRefresh: false,
              },
              {
                env: "sdc",
                cards: [
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodiambuildinfo/s1op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodiambuildinfo/s2op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodiambuildinfo/s3op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodiambuildinfo/s4op1",
                  },
                ],
                reloadCount: 0,
                isRefresh: false,
              },
            ],
          },
          {
            env: "AA services",
            subEnvironment: [
              {
                env: "tdc",
                cards: [
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/t1op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/t2op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/t3op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/t4op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/t5op1",
                  },
                ],
                reloadCount: 0,
                isRefresh: false,
              },
              {
                env: "sdc",
                cards: [
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/s1op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/s2op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/s3op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/s4op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodcvsbuildinfo/s5op1",
                  },
                ],
                reloadCount: 0,
                isRefresh: false,
              },
            ],
          },
          {
            env: "B2B services",
            subEnvironment: [
              {
                env: "tdc",
                cards: [
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodb2bbuildinfo/t1op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodb2bbuildinfo/t2op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodb2bbuildinfo/t3op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodb2bbuildinfo/t4op1",
                  },
                ],
                reloadCount: 0,
                isRefresh: false,
              },
              {
                env: "sdc",
                cards: [
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodb2bbuildinfo/s1op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodb2bbuildinfo/s2op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodb2bbuildinfo/s3op1",
                  },
                  {
                    flag: false,
                    url: "https://mbaccmgrqa4.sdc.vzwcorp.com/prodb2bbuildinfo/s4op1",
                  },
                ],
                reloadCount: 0,
                isRefresh: false,
              },
            ],
          },
        ],
      },
    ],
    isRefresh: false,
    cardData: { name: "", url: "", flag: "" },
    activeTab: "Build Information",
  },
  mutations: {
    setActiveTab(state, tabName) {
      state.activeTab = tabName;
    },
    setTotalVzAuth(state, payload) {
      state.totalVzAuth = [...payload];
    },
    setIsRefresh(state, payload) {
      // Global Refresh
      if (typeof payload == "boolean") state.isRefresh = payload;
      // Individual card refresh
      else {
        const tabData = state.totalVzAuth.find(
          (tab) => tab.tabName === payload.tabName
        );
        const card =
          tabData.tabName === "Build Information"
            ? tabData["data"][payload.index]
            : tabData.data[payload.index].subEnvironment[payload.subEnvIndex];
        if (card) card.isRefresh = payload.value;
      }
    },
    // Reload the cards when reloadcount increase
    setReloadCardData(state, payload) {
      const tabData = state.totalVzAuth.find(
        (tab) => tab.tabName === payload.tabName
      );
      const card =
        tabData.tabName === "Build Information"
          ? tabData["data"][payload.index]
          : tabData.data[payload.index].subEnvironment[payload.subEnvIndex];
      if (card) card.reloadCount = card.reloadCount + 1;
    },
  },
  actions: {
    updateActiveTab({ commit }, tabName) {
      commit("setActiveTab", tabName);
    },
    updateTotalVzAuth({ commit }, payload) {
      commit("setTotalVzAuth", payload);
    },
    reloadCardData({ commit }, payload) {
      commit("setIsRefresh", { ...payload, value: true });
      commit("setReloadCardData", payload);
      setTimeout(() => {
        commit("setIsRefresh", { ...payload, value: false });
      }, 2000);
    },
    pageRefresh({ commit }) {
      commit("setIsRefresh", true);
      setTimeout(() => {
        commit("setIsRefresh", false);
      }, 2000);
    },
  },
  getters: {
    getTotalVzAuth: (state) => state.totalVzAuth,
    getRefresh: (state) => state.isRefresh,
    getCardData: (state) => state.CardData,
    getActiveTab: (state) => state.activeTab,
  },
};
